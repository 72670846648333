import mock from "../mock";
import { paginateArray } from "../utils";

const data = {
  users: [
    {
      id: 1,
      nameResident: "Galen Slixby",
      role: "residente",
      username: "Coeficiente: 12",
      email: "gslixby0@gmal.com",
      currentPlan: "Apto 105",
      status: "inactivo",
      file: true,
      avatar: "",
    },
    {
      id: 2,
      nameResident: "Pedro Gomez",
      role: "residente",
      username: "Coeficiente: 3",
      email: "test0@gmal.com",
      currentPlan: "Apto 103",
      status: "activo",
      file: false,
      avatar: "",
    },
    {
      id: 3,
      nameResident: "Andres Perez",
      role: "residente",
      username: "Coeficiente: 2",
      email: "test@gmal.com",
      currentPlan: "Apto 104",
      status: "pendiente",
      file: false,
      avatar: "",
    },
  ],
};

// GET ALL DATA
mock.onGet("/api/users/list/all-data").reply(200, data.users);

// GET Updated DATA
mock.onGet("/api/users/list/data").reply((config) => {
  const {
    q = "",
    perPage = 10,
    page = 1,
    currentPlan = null,
    status = null,
  } = config;

  /* eslint-disable  */
  const queryLowered = q.toLowerCase();
  const filteredData = data.users.filter(
    (user) =>
      (user.username.toLowerCase().includes(queryLowered) ||
        user.nameResident.toLowerCase().includes(queryLowered)) &&
      user.currentPlan === (currentPlan || user.currentPlan) &&
      user.status === (status || user.status)
  );
  /* eslint-enable  */

  return [
    200,
    {
      users: paginateArray(filteredData, perPage, page),
      total: filteredData.length,
    },
  ];
});

// GET USER
mock.onGet("/api/users/user").reply((config) => {
  const { id } = config;
  const user = data.users.find((i) => i.id === id);
  return [200, { user }];
});
